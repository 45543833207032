<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'customer-create create-form'"
    v-if="getPermission('customer:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="equipmentId > 0"> Update Customer Asset </template>
        <template v-else> Add Customer Asset</template>
        <span class="ml-3 orange--text">
          <template v-if="formLoading">
            <v-skeleton-loader
              class="custom-skeleton height-20px custom-skeleton-full-width"
              width="100"
              type="text"
            >
            </v-skeleton-loader>
          </template>
          <template v-else> #{{ unique_barcode }} </template>
        </span>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        depresed
        v-if="!isCustomer"
        v-on:click="goBack"
        :disabled="formLoading"
        depressed
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>

      <v-btn
        depresed
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
        depressed
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="assetForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-tabs
          v-model="assetTab"
          background-color="transparent"
          centered
          color="cyan"
          fixed-tabs
          grow
          class="custom-tab-transparent"
          hide-slider
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              v-if="getPermission(tab.permission + ':create') && tab.status"
              :key="index"
              :href="'#tab-' + tab.key"
            >
              <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-row>
          <v-col cols="12" :md="3" :sm="3" class="text-center">
            <template>
              <v-img
                :lazy-src="$defaultImage"
                :src="getProfileImage ? getProfileImage : $defaultImage"
                max-height="200"
                max-width="200"
                height="200px"
                width="200px"
                class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
              >
              </v-img>
              <div class="margin-auto text-center">
                <v-btn
                  class="my-4 custom-bold-button"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template v-if="getProfileImage">Change Image</template>
                  <template v-else>Upload Image</template>
                </v-btn>

                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
          <v-col cols="12" :md="7" :sm="7" class="pt-0">
            <v-tabs-items v-model="assetTab" class="pb-20">
              <v-tab-item :value="'tab-overview'">
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0 px-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <perfect-scrollbar
                      :options="{ suppressScrollX: true }"
                      class="scroll"
                      style="max-height: 72vh; position: relative"
                    >
                      <v-container fluid class="px-0">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Core Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row v-if="!equipmentId">
                              <v-col lg="12" class="d-flex pt-0">
                                <RadioInputCustom
                                  class="mt-0"
                                  v-model="asset.asset_type"
                                  :disabled="asset.main_type == 'main_company'"
                                  row
                                  color="blue darken-4"
                                  v-on:change="
                                    () => {
                                      asset.name = null;
                                      asset.product = 0;
                                    }
                                  "
                                  :items="[
                                    {
                                      label: 'Customer Own Purchased Asset',
                                      value: 'personal',
                                    },
                                    {
                                      label: `Asset Purchased from ${appCompanyName}`,
                                      value: 'company',
                                    },
                                  ]"
                                ></RadioInputCustom>
                              </v-col>
                            </v-row>
                            <template v-if="asset.main_type == 'main_customer'">
                              <v-row>
                                <v-col cols="6" v-if="!isCustomer">
                                  <label
                                    for="customer-equipment"
                                    class="font-weight-700 font-size-16 required"
                                    >Customer</label
                                  >
                                  <v-autocomplete
                                    hide-details
                                    :readonly="equipmentId > 0 ? true : false"
                                    id="customer-equipment"
                                    :items="customerList"
                                    dense
                                    flat
                                    filled
                                    placeholder="Customer"
                                    :rules="[
                                      validateRules.required(
                                        asset.customer,
                                        'customer'
                                      ),
                                    ]"
                                    item-color="cyan"
                                    color="cyan"
                                    solo
                                    v-model.trim="asset.customer"
                                    item-text="display_name"
                                    item-value="id"
                                    v-on:change="filter_property($event)"
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title
                                          v-html="'No Customer(s) Found.'"
                                        ></v-list-item-title>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:item="{ item }">
                                      <v-list-item-avatar>
                                        <v-img
                                          :lazy-src="$defaultProfileImage"
                                          :src="$assetAPIURL(item.profile_logo)"
                                          aspect-ratio="1"
                                          class="margin-auto"
                                          transition="fade-transition"
                                        ></v-img>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="text-capitalize font-weight-500 font-size-16"
                                          >{{
                                            item.display_name
                                          }}</v-list-item-title
                                        >
                                        <v-list-item-title
                                          class="text-capitalize font-weight-500 font-size-16"
                                          >{{
                                            item.company_name
                                          }}</v-list-item-title
                                        >
                                      </v-list-item-content>
                                      <v-list-item-action
                                        v-if="item.default_person"
                                        class="align-self-center"
                                      >
                                        <v-list-item-subtitle
                                          class="text-lowercase pb-2 font-weight-500 font-size-14"
                                          >{{
                                            item.default_person.primary_email
                                          }}</v-list-item-subtitle
                                        >
                                        <v-list-item-subtitle
                                          class="text-lowercase font-weight-500 font-size-14"
                                          >{{
                                            item.default_person.primary_phone
                                          }}</v-list-item-subtitle
                                        >
                                      </v-list-item-action>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col lg="6">
                                  <label
                                    class="pr-2 font-weight-700 required font-size-16 width-100"
                                    >Customer's Asset Serial No</label
                                  >
                                  <v-text-field
                                    id="Serial No."
                                    v-model.trim="asset.serial_no"
                                    @keypress="
                                      ($event) => blockSpecialCharacters($event)
                                    "
                                    @paste="($event) => handlePaste($event)"
                                    :rules="[
                                      validateRules.required(
                                        asset.serial_no,
                                        'Serial No.'
                                      ),
                                    ]"
                                    dense
                                    filled
                                    placeholder="Serial No."
                                    solo
                                    flat
                                    color="cyan"
                                    class="width-100"
                                  ></v-text-field>
                                  <!--required-field-->
                                </v-col>
                                <v-col :lg="isCustomer ? 12 : 6" v-if="false">
                                  <label
                                    for="property-equipment"
                                    class="font-weight-700 font-size-16 required"
                                    >Site Location</label
                                  >
                                  <v-autocomplete
                                    hide-details
                                    id="property-equipment"
                                    :readonly="equipmentId > 0 ? true : false"
                                    :items="filtered_property"
                                    :rules="[
                                      validateRules.required(
                                        asset.property,
                                        'property'
                                      ),
                                    ]"
                                    v-model.trim="asset.property"
                                    dense
                                    flat
                                    filled
                                    placeholder="Site Location"
                                    item-color="cyan"
                                    color="cyan"
                                    solo
                                    item-text="barcode"
                                    item-value="id"
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title
                                          v-html="'No Site Location(s) Found.'"
                                        ></v-list-item-title>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:item="{ item }">
                                      <v-list-item-icon class="mr-3">
                                        <v-icon large
                                          >mdi-home-map-marker</v-icon
                                        >
                                      </v-list-item-icon>

                                      <v-list-item-content>
                                        <v-layout style="max-width: 150px">
                                          <v-flex>
                                            <Barcode
                                              class="my-0 mt-0 mb-0 mr-1"
                                              :barcode="item.barcode"
                                            ></Barcode>
                                          </v-flex>
                                          <v-flex v-if="item.is_tenant">
                                            <v-chip
                                              class="mx-1"
                                              label
                                              small
                                              color="green"
                                              outlined
                                              >Tenant</v-chip
                                            >
                                          </v-flex>
                                          <v-flex>
                                            <v-chip
                                              label
                                              small
                                              class="ml-1"
                                              :color="
                                                item.type == 1
                                                  ? 'cyan'
                                                  : 'orange'
                                              "
                                              outlined
                                            >
                                              <template v-if="item.type == 1"
                                                >Site Location</template
                                              >
                                              <template v-else
                                                >Billing</template
                                              >
                                            </v-chip>
                                          </v-flex>
                                        </v-layout>
                                        <v-list-item-title
                                          class="text-capitalize font-weight-500 font-size-16"
                                          >{{ get_formatted_property(item) }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col
                                  lg="6"
                                  v-if="asset.asset_type == 'company'"
                                >
                                  <label
                                    for="customer-equipment"
                                    class="font-weight-700 font-size-16 required"
                                    >Product</label
                                  >
                                  <v-autocomplete
                                    :filter="customFilter"
                                    hide-details
                                    id="customer-equipment"
                                    :items="products"
                                    v-model.trim="asset.product"
                                    dense
                                    flat
                                    filled
                                    placeholder="Product"
                                    item-color="cyan"
                                    color="cyan"
                                    solo
                                    item-text="barcode"
                                    :rules="[
                                      validateRules.required(
                                        asset.product,
                                        'product'
                                      ),
                                    ]"
                                    v-on:change="productData($event)"
                                    item-value="id"
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title
                                          v-html="'No Product(s) Found.'"
                                        ></v-list-item-title>
                                      </v-list-item>
                                    </template>
                                    <template v-slot:item="{ item }">
                                      <v-list-item-avatar>
                                        <v-img
                                          :lazy-src="$defaultImage"
                                          :src="
                                            item &&
                                            item.product_image &&
                                            item.product_image.file &&
                                            item.product_image.file.url
                                          "
                                          aspect-ratio="1"
                                          class="margin-auto"
                                          transition="fade-transition"
                                        ></v-img>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="text-capitalize font-weight-500 font-size-16"
                                          >{{ item.name }}</v-list-item-title
                                        >
                                        <v-list-item-title
                                          class="text-capitalize font-weight-500 font-size-16"
                                          >{{ item.barcode }}</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col lg="6">
                                  <label
                                    class="pr-2 font-weight-700 required font-size-16 width-100"
                                  >
                                    Customer's Asset Name</label
                                  >
                                  <TextField
                                    id="name"
                                    v-model.trim="asset.name"
                                    :rules="[
                                      validateRules.required(
                                        asset.name,
                                        'name'
                                      ),
                                    ]"
                                    dense
                                    filled
                                    placeholder="Name"
                                    solo
                                    flat
                                    color="cyan"
                                    class="width-100"
                                    counter="50"
                                    :maxlength="250"
                                  ></TextField>
                                  <!--required-field-->
                                </v-col>

                                <v-col md="12" v-if="asset.product">
                                  <v-layout class="nmt-1 bg-quo">
                                    <v-flex
                                      md6
                                      class="mr-2 custom-border-right"
                                    >
                                      <table width="100%">
                                        <tr>
                                          <td class="p-0">
                                            <v-img
                                              v-if="
                                                filteredProduct.product_image
                                              "
                                              :lazy-src="$defaultImage"
                                              :src="
                                                filteredProduct &&
                                                filteredProduct.product_image &&
                                                filteredProduct.product_image
                                                  .file &&
                                                filteredProduct.product_image
                                                  .file.url
                                              "
                                              aspect-ratio="1"
                                              transition="fade-transition"
                                              max-height="60"
                                              max-width="60"
                                              height="60px"
                                              width="60px"
                                              class="custom-grey-border custom-border-radius-50 company-image-logo"
                                            ></v-img>
                                            <v-img
                                              v-else
                                              :lazy-src="$defaultImage"
                                              :src="$defaultImage"
                                              aspect-ratio="1"
                                              transition="fade-transition"
                                              max-height="60"
                                              max-width="60"
                                              height="60px"
                                              width="60px"
                                              class="custom-grey-border custom-border-radius-50 company-image-logo"
                                            ></v-img>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            filteredProduct &&
                                            filteredProduct.serial_number
                                          "
                                        >
                                          <td
                                            class="font-weight-600 pt-1 pb-0"
                                            width="20%"
                                          >
                                            <label>Item no:</label>
                                          </td>
                                          <td class="pt-1 pb-0">
                                            <span
                                              class="mr-2"
                                              v-if="
                                                filteredProduct &&
                                                filteredProduct.serial_number
                                              "
                                            >
                                              <v-chip
                                                label
                                                small
                                                class="white--text"
                                                color="blue
                                                "
                                                outlined
                                                >{{
                                                  filteredProduct.serial_number
                                                }}
                                              </v-chip>
                                            </span>
                                          </td>
                                        </tr>

                                        <tr
                                          v-if="
                                            filteredProduct &&
                                            filteredProduct.name
                                          "
                                        >
                                          <td
                                            class="font-weight-600 pt-0 pb-0"
                                            width="18%"
                                          >
                                            <label>Name:</label>
                                          </td>
                                          <td class="pt-0 pb-0">
                                            <span
                                              class="mr-2"
                                              v-if="
                                                filteredProduct &&
                                                filteredProduct.name
                                              "
                                            >
                                              <label>{{
                                                filteredProduct.name
                                              }}</label></span
                                            >
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            filteredProduct &&
                                            filteredProduct.category_name
                                          "
                                        >
                                          <td
                                            class="font-weight-600 pt-0 pb-0"
                                            width="18%"
                                          >
                                            <label>Category:</label>
                                          </td>
                                          <td class="pt-0 pb-0">
                                            <span
                                              class="mr-2"
                                              v-if="
                                                filteredProduct &&
                                                filteredProduct.category_name
                                              "
                                            >
                                              <label
                                                v-if="
                                                  filteredProduct &&
                                                  filteredProduct.category_name
                                                "
                                                >{{
                                                  filteredProduct &&
                                                  filteredProduct.category_name
                                                }}</label
                                              >
                                            </span>
                                            <em v-else class="text-muted">
                                              no category</em
                                            >
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            filteredProduct &&
                                            filteredProduct.brand
                                          "
                                        >
                                          <td
                                            class="font-weight-600 pt-0 pb-0"
                                            width="18%"
                                          >
                                            <label>Brand:</label>
                                          </td>
                                          <td class="pt-0 pb-0">
                                            <span
                                              class="mr-2"
                                              v-if="
                                                filteredProduct &&
                                                filteredProduct.brand
                                              "
                                            >
                                              <label
                                                v-if="
                                                  filteredProduct &&
                                                  filteredProduct.brand
                                                "
                                                >{{
                                                  filteredProduct &&
                                                  filteredProduct.brand
                                                }}</label
                                              >
                                            </span>
                                            <em v-else class="text-muted">
                                              no brand</em
                                            >
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            filteredProduct &&
                                            filteredProduct.model
                                          "
                                        >
                                          <td
                                            class="font-weight-600 pt-0 pb-0"
                                            width="18%"
                                          >
                                            <label>Model No:</label>
                                          </td>
                                          <td class="pt-0 pb-0">
                                            <span
                                              class="mr-2"
                                              v-if="
                                                filteredProduct &&
                                                filteredProduct.model
                                              "
                                            >
                                              <label
                                                v-if="
                                                  filteredProduct &&
                                                  filteredProduct.model
                                                "
                                                >{{
                                                  filteredProduct &&
                                                  filteredProduct.model
                                                }}</label
                                              >
                                            </span>
                                            <em v-else class="text-muted">
                                              no model</em
                                            >
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            filteredProduct &&
                                            filteredProduct.model_no
                                          "
                                          width="18%"
                                        >
                                          <td class="font-weight-600 pt-0 pb-0">
                                            <label>Model no.:</label>
                                          </td>
                                          <td class="pt-0 pb-0">
                                            <span
                                              class="mr-2"
                                              v-if="
                                                filteredProduct &&
                                                filteredProduct.model_no
                                              "
                                            >
                                              <label
                                                v-if="
                                                  filteredProduct &&
                                                  filteredProduct.model_no
                                                "
                                                >{{
                                                  filteredProduct &&
                                                  filteredProduct.model_no
                                                }}</label
                                              >
                                            </span>
                                            <em v-else class="text-muted">
                                              no model no</em
                                            >
                                          </td>
                                        </tr>
                                      </table>
                                    </v-flex>
                                    <v-flex md6 class="mr-2">
                                      <table width="100%">
                                        <tr>
                                          <td class="font-weight-600">
                                            <label>Description</label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            class="font-weight-600 pl-2"
                                            v-if="
                                              filteredProduct &&
                                              filteredProduct.description
                                            "
                                          >
                                            {{ filteredProduct.description }}
                                          </td>
                                          <em v-else class="text-muted">
                                            no description</em
                                          >
                                        </tr>
                                      </table>
                                    </v-flex>
                                  </v-layout>
                                </v-col>
                              </v-row>
                            </template>
                            <v-row v-if="false">
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Installation Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Installation Date"
                                  v-model="asset.installation_date"
                                  :min-date="minDate"
                                  :default-date="asset.installation_date"
                                ></DatePicker>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Commission Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Commission Date"
                                  v-model="asset.activation_date"
                                  :min-date="minDate"
                                  :default-date="asset.activation_date"
                                ></DatePicker>
                              </v-col>
                            </v-row>

                            <v-row v-if="false">
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Batch Number</label
                                >
                                <v-text-field
                                  id="batch_number"
                                  v-model.trim="asset.batch_number"
                                  dense
                                  filled
                                  placeholder="Batch Number"
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Selling Price</label
                                >
                                <v-text-field
                                  :min="0"
                                  oninput="if(this.value < 0) this.value = 0;"
                                  type="number"
                                  hide-details
                                  dense
                                  solo
                                  flat
                                  :disabled="formLoading"
                                  :loading="formLoading"
                                  placeholder="Selling-Price"
                                  hide-spin-buttons
                                  prepend-inner-icon="mdi-currency-usd"
                                  v-model="asset.selling_price"
                                  v-on:keypress="
                                    limitDecimal($event, asset.selling_price)
                                  "
                                  class="mt-0"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col lg="6">
                                <div class="d-flex justify-space-between">
                                  <label
                                    class="pr-2 font-weight-700 font-size-16 width-100"
                                    >Brand</label
                                  >
                                  <v-icon small @click="manage_brand = true"
                                    >mdi-cog</v-icon
                                  >
                                </div>
                                <v-autocomplete
                                  hide-details
                                  id="brand-equipment"
                                  :items="brands"
                                  dense
                                  flat
                                  filled
                                  placeholder="Brand"
                                  item-color="cyan"
                                  v-model="asset.brand"
                                  color="cyan"
                                  solo
                                  item-text="text"
                                  item-value="text"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title
                                        v-html="'No Brand(s) Found.'"
                                      ></v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col lg="6">
                                <div class="d-flex justify-space-between">
                                  <label
                                    class="pr-2 font-weight-700 font-size-16 width-100"
                                    >Category</label
                                  >
                                  <v-icon
                                    small
                                    @click="manageGroupDialog = true"
                                    >mdi-cog</v-icon
                                  >
                                </div>
                                <div class="d-flex">
                                  <v-autocomplete
                                    dense
                                    filled
                                    color="cyan"
                                    item-color="cyan"
                                    :items="categoryList"
                                    placeholder="Category"
                                    solo
                                    flat
                                    item-text="text"
                                    item-value="text"
                                    class="width-100"
                                    v-model.trim="asset.category"
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            v-html="'No Category(s) Found.'"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-autocomplete>
                                </div>
                              </v-col>
                              <v-col lg="6" v-if="false">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >End Life</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="End Life"
                                  v-model="asset.end_life"
                                  :min-date="minDate"
                                  :default-date="asset.end_life"
                                ></DatePicker>
                              </v-col>
                            </v-row>
                            <v-row v-if="false">
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Contact for Item</label
                                >

                                <PhoneTextField
                                  v-model="asset.company_number"
                                ></PhoneTextField>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Model No.</label
                                >
                                <v-text-field
                                  id="model-equipment"
                                  dense
                                  filled
                                  placeholder="Model"
                                  solo
                                  v-model="asset.model"
                                  flat
                                  color="cyan"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Specification</label
                                >
                                <v-text-field
                                  id="specification"
                                  dense
                                  filled
                                  placeholder="Enter specifications, if any"
                                  solo
                                  v-model="asset.specification"
                                  flat
                                  color="cyan"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Tags</label
                                >
                                <TagAutoComplete
                                  type="client_equipment"
                                  v-model="asset.tags"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="12">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Customer Asset Description</label
                                >
                                <TextAreaField
                                  auto-grow
                                  dense
                                  filled
                                  color="cyan"
                                  v-model.trim="asset.description"
                                  placeholder="Description"
                                  solo
                                  flat
                                  row-height="20"
                                  counter="200"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <!-- Events & Site Information-->
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mt-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Events & Site Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Installation Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Installation Date"
                                  v-model="asset.installation_date"
                                  :min-date="minDate"
                                  :default-date="asset.installation_date"
                                ></DatePicker>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Commission Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Commission Date"
                                  v-model="asset.activation_date"
                                  :min-date="minDate"
                                  :default-date="asset.activation_date"
                                  v-on:change="getDates()"
                                ></DatePicker>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >End Life</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="End Life"
                                  v-model="asset.end_life"
                                  :min-date="asset.installation_date"
                                  :default-date="asset.end_life"
                                  v-on:change="getDates()"
                                >
                                </DatePicker>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Useful life / lifespan</label
                                >
                                <div class="mt-2 font-weight-700">
                                  <template v-if="getDates()">{{
                                    getDates()
                                  }}</template
                                  ><template v-else>-</template>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <div class="d-flex justify-space-between">
                                  <label
                                    class="pr-2 font-weight-700 font-size-16 width-100 required"
                                    >Location
                                  </label>
                                </div>
                                <v-autocomplete
                                  :rules="[
                                    validateRules.required(
                                      asset.property,
                                      'property'
                                    ),
                                  ]"
                                  hide-details
                                  id="property-equipment"
                                  :readonly="equipmentId > 0 ? true : false"
                                  :items="filtered_property"
                                  v-model.trim="asset.property"
                                  dense
                                  flat
                                  filled
                                  placeholder="Location"
                                  item-color="cyan"
                                  color="cyan"
                                  solo
                                  item-text="barcode"
                                  item-value="id"
                                  @change="getLocation(asset.property)"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title
                                        v-html="'No Site Location(s) Found.'"
                                      ></v-list-item-title>
                                    </v-list-item>
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-list-item-icon class="mr-3">
                                      <v-icon large>mdi-home-map-marker</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                      <v-layout style="max-width: 150px">
                                        <v-flex>
                                          <Barcode
                                            class="my-0 mt-0 mb-0 mr-1"
                                            :barcode="item.barcode"
                                          ></Barcode>
                                        </v-flex>
                                        <v-flex v-if="item.is_tenant">
                                          <v-chip
                                            class="mx-1"
                                            label
                                            small
                                            color="green"
                                            outlined
                                            >Tenant</v-chip
                                          >
                                        </v-flex>
                                        <v-flex>
                                          <v-chip
                                            label
                                            small
                                            class="ml-1"
                                            :color="
                                              item.type == 1 ? 'cyan' : 'orange'
                                            "
                                            outlined
                                          >
                                            <template v-if="item.type == 1"
                                              >Site Location</template
                                            >
                                            <template v-else>Billing</template>
                                          </v-chip>
                                        </v-flex>
                                      </v-layout>
                                      <v-list-item-title
                                        class="text-capitalize font-weight-500 font-size-16"
                                        >{{ get_formatted_property(item) }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-autocomplete>
                                <!--  <v-autocomplete
                                  hide-details
                                  id="location-equipment"
                                  :items="LocationList"
                                  dense
                                  v-model="asset.site_location"
                                  flat
                                  filled
                                  placeholder="Location"
                                  item-color="cyan"
                                  color="cyan"
                                  solo
                                  item-text="text"
                                  item-value="id"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title
                                        v-html="'No Location(s) Found.'"
                                      ></v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete> -->
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Address
                                </label>
                                <div class="" v-if="showlocation">
                                  {{ get_formatted_property(showlocation) }}
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <div class="d-flex justify-space-between">
                                  <label
                                    class="pr-2 font-weight-700 font-size-16 width-100"
                                    >Sub-Location
                                  </label>
                                  <!--  <v-icon @click="manage_location = true" small
                                    >mdi-cog</v-icon
                                  > -->
                                </div>
                                <v-autocomplete
                                  hide-details
                                  id="sub_location"
                                  :items="equipment_locations"
                                  dense
                                  v-model="asset.location"
                                  flat
                                  filled
                                  placeholder="Sub-Location"
                                  item-color="cyan"
                                  color="cyan"
                                  solo
                                  item-text="text"
                                  item-value="id"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title
                                        v-html="'No Sub-Location(s) Found.'"
                                      ></v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col lg="6"> </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <!-- Events & Site Information end-->

                        <!--Purchase Information-->
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mt-3"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Customer Asset Sales Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Sales Person</label
                                >
                                <v-text-field
                                  id="sales_person"
                                  v-model.trim="asset.salesperson"
                                  dense
                                  filled
                                  placeholder="Sales person"
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                >
                                  Supplier</label
                                >
                                <v-autocomplete
                                  hide-details
                                  id="supplier"
                                  :items="supplierList"
                                  dense
                                  v-model="asset.vendor"
                                  flat
                                  filled
                                  placeholder="Supplier"
                                  item-color="cyan"
                                  color="cyan"
                                  solo
                                  item-text="display_name"
                                  item-value="id"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-title
                                        v-html="'No Supplier(s) Found.'"
                                      ></v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                >
                                  Purchase Price</label
                                >
                                <v-text-field
                                  :min="0"
                                  oninput="if(this.value < 0) this.value = 0;"
                                  type="number"
                                  hide-details
                                  dense
                                  solo
                                  flat
                                  :disabled="formLoading"
                                  :loading="formLoading"
                                  placeholder="Purchase Price"
                                  hide-spin-buttons
                                  prepend-inner-icon="mdi-currency-usd"
                                  v-model="asset.purchase_price"
                                  v-on:keypress="
                                    limitDecimal($event, asset.purchase_price)
                                  "
                                  class="mt-0"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                >
                                  Purchase Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder=" Purchase Date"
                                  v-model="asset.purchase_date"
                                  :default-date="asset.purchase_date"
                                ></DatePicker>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Quotation No.</label
                                >
                                <v-text-field
                                  id="quotation_no"
                                  v-model.trim="asset.quotation_no"
                                  dense
                                  filled
                                  placeholder="Quotation No."
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                >
                                  Invoice No.</label
                                >
                                <v-text-field
                                  id="invoice_no"
                                  v-model.trim="asset.invoice_no"
                                  dense
                                  filled
                                  placeholder="Invoice No."
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <!--Purchase Information end-->

                        <!--Delivery Info-->
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mt-3"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Customer Asset Delivery Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >DO No.</label
                                >
                                <v-text-field
                                  id="do_no"
                                  v-model.trim="asset.do_no"
                                  dense
                                  filled
                                  placeholder="DO No."
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>

                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Commission Cert No.</label
                                >
                                <v-text-field
                                  id="common_cert_no"
                                  v-model.trim="asset.common_cert_no"
                                  dense
                                  filled
                                  placeholder="Commission Cert No."
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6" v-if="false">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Sales Employee</label
                                >
                                <v-text-field
                                  id="sales_employee"
                                  v-model.trim="asset.sales_employee"
                                  dense
                                  filled
                                  placeholder="Sales Employee"
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Arrival Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Arrival Date"
                                  v-model="asset.arrival_date"
                                  :default-date="asset.arrival_date"
                                ></DatePicker>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Delivery Date</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Delivery Date"
                                  v-model="asset.delivery_date"
                                  :default-date="asset.delivery_date"
                                ></DatePicker>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="12">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Delivery Remark</label
                                >
                                <TextAreaField
                                  auto-grow
                                  dense
                                  filled
                                  color="cyan"
                                  v-model.trim="asset.delivery_remark"
                                  placeholder="Delivery Remark"
                                  solo
                                  flat
                                  row-height="25"
                                  counter="200"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <!--Delivery Info end-->

                        <v-card
                          v-if="
                            asset.is_purchase_info &&
                            asset.asset_type == 'company' &&
                            false
                          "
                          flat
                          class="custom-grey-border remove-border-radius mt-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Purchased Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Cost Price</label
                                >
                                <v-text-field
                                  id="cost_price"
                                  v-model.trim="asset.cost_price"
                                  dense
                                  filled
                                  placeholder="Cost Price"
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Purchased On</label
                                >
                                <DatePicker
                                  solo
                                  flat
                                  :mandatory="false"
                                  :nullable="true"
                                  placeholder="Purchased On"
                                  v-model="asset.purchased_on"
                                  :default-date="asset.purchased_on"
                                ></DatePicker>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="12">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Supplier</label
                                >
                                <v-autocomplete
                                  :items="supplierList"
                                  dense
                                  filled
                                  color="cyan"
                                  item-color="cyan"
                                  placeholder="Supplier"
                                  solo
                                  flat
                                  item-text="display_name"
                                  item-value="id"
                                  class="pl-2 width-100"
                                  v-model.trim="asset.vendor"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="'No Supplier(s) Found.'"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <!-- Is there any warranty ? -->
                        <v-row>
                          <v-col lg="12" class="d-flex">
                            <label>Is there any warranty ? </label>
                            <v-switch
                              class="ml-4"
                              color="cyan"
                              dense
                              :readonly="equipmentId > 0 ? true : false"
                              inset
                              hide-details
                              v-on:change="changeWarrantyCheck"
                              v-model="asset.check_warranty"
                            ></v-switch>
                          </v-col>
                          <v-col lg="12" class="d-flex" v-if="false">
                            <label
                              >Is there any Preventive Maintenance ?
                            </label>
                            <v-switch
                              class="ml-4"
                              color="cyan"
                              dense
                              :readonly="equipmentId > 0 ? true : false"
                              inset
                              hide-details
                              v-on:change="changeMaintananceCheck"
                              v-model="asset.check_maintanance"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-container>
                    </perfect-scrollbar>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <v-tab-item
                :value="'tab-warranty'"
                v-if="assetTab == 'tab-warranty'"
              >
                <v-layout class="iv-custom-field pa-0">
                  <v-col
                    class="custom-content-container pt-0 px-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <perfect-scrollbar
                      :options="{ suppressScrollX: true }"
                      class="scroll"
                      style="
                        max-height: calc(100vh - 150px);
                        position: relative;
                      "
                    >
                      <v-container fluid class="px-0">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Warranty Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  for="asset_type"
                                  >Warranty Duration
                                </label>
                                <v-text-field
                                  type="number"
                                  id="duration"
                                  v-model.trim="asset.warranty_duration"
                                  dense
                                  filled
                                  placeholder="Duration"
                                  solo
                                  :disabled="equipmentId > 0 ? true : false"
                                  flat
                                  color="cyan"
                                  class="pr-2 width-100"
                                  v-on:keyup="
                                    (e) =>
                                      updateWarrantyDuration(
                                        e,
                                        'warranty_duration'
                                      )
                                  "
                                  v-mask="'###'"
                                  :min="0"
                                  oninput="if(this.value < 0) this.value = 0;"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  for="asset_type"
                                  >Warranty Duration Type</label
                                >
                                <v-autocomplete
                                  :items="durationTypeList"
                                  dense
                                  :disabled="equipmentId > 0 ? true : false"
                                  filled
                                  color="cyan"
                                  item-color="cyan"
                                  placeholder="Duration Type"
                                  solo
                                  v-on:change="
                                    (e) =>
                                      updateWarrantyDuration(
                                        e,
                                        'warranty_duration_type'
                                      )
                                  "
                                  flat
                                  item-text="text"
                                  item-value="value"
                                  class="width-100"
                                  v-model.trim="asset.warranty_duration_type"
                                >
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Start Date
                                </label>
                                <div class="pr-2">
                                  <DatePicker
                                    solo
                                    :disabled="dateDisable()"
                                    flat
                                    :rules="[
                                      validateRules.required(
                                        asset.warranty_start_date,
                                        'warranty_start_date'
                                      ),
                                    ]"
                                    :nullable="true"
                                    placeholder="Start Date"
                                    v-model="asset.warranty_start_date"
                                    :default-date="asset.warranty_start_date"
                                    v-on:change="
                                      (e) =>
                                        updateWarrantyDuration(
                                          e,
                                          'warranty_start_date'
                                        )
                                    "
                                    :offWeekend="false"
                                  ></DatePicker>
                                </div>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >End Date</label
                                >
                                <div class="pl-2">
                                  <div class="ml-1 black--text">
                                    {{ formatDate(asset.warranty_end_date) }}
                                  </div>
                                  <!--   <v-text-field
                                        id="job-id"
                                        dense
                                        filled
                                        class="font-weight-700"
                                        readonly
                                        hide-details
                                        placeholder="End Date"
                                        solo
                                        flat
                                        :value="formatDate(asset.warranty_end_date)"
                                        color="cyan"
                                      /> -->
                                  <!--   <DatePicker
                                solo
                                flat
                                :disabled="dateDisable()"
                                :rules="[
                                  validateRules.required(
                                    asset.warranty_end_date,
                                    'warranty_end_date'
                                  ),
                                ]"
                                :nullable="true"
                                placeholder="End Date"
                                v-model="asset.warranty_end_date"
                                :default-date="asset.warranty_end_date"
                                :min-date="asset.warranty_start_date"
                                v-on:change="
                                  (e) =>
                                    updateWarrantyDuration(
                                      e,
                                      'warranty_end_date'
                                    )
                                "
                                :offWeekend="false"
                              ></DatePicker> -->
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Cost</label
                                >
                                <v-text-field
                                  type="number"
                                  id="cost"
                                  v-model.trim.number="asset.warranty_cost"
                                  dense
                                  filled
                                  placeholder="Cost"
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                  :disabled="equipmentId > 0 ? true : false"
                                  :min="0"
                                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                ></v-text-field>
                                <!--oninput="if(this.value < 0) this.value = 0;"-->
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100 required"
                                  >Reference No.</label
                                >
                                <v-text-field
                                  dense
                                  filled
                                  :disabled="equipmentId > 0 ? true : false"
                                  :rules="[
                                    validateRules.required(
                                      asset.warranty_reference,
                                      'Reference No.'
                                    ),
                                  ]"
                                  color="cyan"
                                  placeholder="Reference No."
                                  solo
                                  flat
                                  v-model.trim="asset.warranty_reference"
                                ></v-text-field>
                                <!--oninput="if(this.value < 0) this.value = 0;"-->
                              </v-col>
                              <v-col lg="12">
                                <label
                                  class="pr-2 font-weight-700 font-size-16 width-100"
                                  >Warranty Description</label
                                >
                                <TextAreaField
                                  auto-grow
                                  dense
                                  filled
                                  :disabled="equipmentId > 0 ? true : false"
                                  color="cyan"
                                  v-model.trim="asset.warranty_remark"
                                  placeholder="Description"
                                  solo
                                  flat
                                  row-height="25"
                                  counter="200"
                                />
                                <!--oninput="if(this.value < 0) this.value = 0;"-->
                              </v-col>
                            </v-row>
                            <template
                              v-if="
                                asset &&
                                asset.warranty_reminder &&
                                asset.warranty_reminder.length > 0 &&
                                false
                              "
                            >
                              <template
                                v-for="(data, index) in asset.warranty_reminder"
                              >
                                <v-row
                                  :key="`file-row-${index}`"
                                  :class="{
                                    'table-alternate-row': index % 2,
                                  }"
                                >
                                  <v-col md="12" class="py-0">
                                    <label
                                      class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                      >Reminder</label
                                    >
                                  </v-col>
                                  <v-col md="10" class="py-0">
                                    <v-row>
                                      <v-col md="12" class="py-0">
                                        <v-layout>
                                          <v-flex md4>
                                            <v-radio-group
                                              hide-details
                                              id="export-type"
                                              class="pt-0"
                                              v-on:change="
                                                manageReminder(
                                                  index,
                                                  'warranty'
                                                )
                                              "
                                              v-model="data.type"
                                              row
                                            >
                                              <v-radio
                                                label="After"
                                                color="cyan"
                                                value="after"
                                              ></v-radio>
                                              <v-radio
                                                label="Before"
                                                color="cyan"
                                                value="before"
                                              ></v-radio>
                                            </v-radio-group>
                                          </v-flex>
                                          <v-flex md4>
                                            <v-text-field
                                              id="duration"
                                              v-model.trim="data.duration"
                                              dense
                                              filled
                                              placeholder="Duration"
                                              solo
                                              flat
                                              color="cyan"
                                              class="pr-2 width-100"
                                              v-on:keyup="
                                                manageReminder(
                                                  index,
                                                  'warranty'
                                                )
                                              "
                                            ></v-text-field>
                                          </v-flex>
                                          <v-flex md4>
                                            <v-autocomplete
                                              :items="durationTypeList"
                                              dense
                                              filled
                                              color="cyan"
                                              item-color="cyan"
                                              placeholder="Duration Type"
                                              solo
                                              flat
                                              item-text="text"
                                              item-value="text"
                                              class="pl-2 width-100"
                                              v-on:change="
                                                manageReminder(
                                                  index,
                                                  'warranty'
                                                )
                                              "
                                              v-model.trim="data.duration_type"
                                            >
                                            </v-autocomplete>
                                          </v-flex>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col md="2" class="my-auto py-0">
                                    <v-row>
                                      <v-col md="6" class="py-0">
                                        <v-btn
                                          :disabled="
                                            asset.warranty_reminder.length < 2
                                          "
                                          v-on:click="
                                            removeWarrantyReminder(index)
                                          "
                                          class="white--text"
                                          depressed
                                          color="red darken-4"
                                          tile
                                          style="margin-left: -10px"
                                        >
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col md="6" class="py-0">
                                        <v-btn
                                          :disabled="
                                            asset.warranty_reminder.length >
                                              4 || equipmentId > 0
                                          "
                                          v-on:click="addMoreWarrantyReminder()"
                                          class="white--text"
                                          depressed
                                          color="cyan"
                                          tile
                                          style="margin-left: -13px"
                                        >
                                          <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                            <!-- Warranty Attachment Start-->
                            <div class="mt-5" v-if="false">
                              <FileUpload v-model="wtx_attachments" />
                            </div>
                            <!-- Warranty Attachment End-->
                            <template
                              v-if="
                                false &&
                                wtx_attachments &&
                                wtx_attachments.length > 0
                              "
                            >
                              <template
                                v-for="(file, indexFile) in wtx_attachments"
                              >
                                <v-row
                                  :key="`warranty-file-row-${indexFile}`"
                                  :class="{
                                    'table-alternate-row': indexFile % 2,
                                  }"
                                >
                                  <v-col md="12" class="py-0">
                                    <label
                                      class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                      >Attachment</label
                                    >
                                  </v-col>
                                  <v-col md="10" class="py-0">
                                    <v-row>
                                      <v-col md="12" class="py-0">
                                        <v-layout>
                                          <v-flex md6>
                                            <v-file-input
                                              :id="`document-file-${indexFile}`"
                                              placeholder="Select File"
                                              class="removeShadow"
                                              prepend-icon=""
                                              dense
                                              solo
                                              prepend-inner-icon="mdi-attachment"
                                              hide-details
                                              v-model="file.file"
                                              v-on:change="
                                                updateFile(indexFile, $event)
                                              "
                                              v-on:click:clear="
                                                updateFile(indexFile, $event)
                                              "
                                            ></v-file-input>
                                          </v-flex>
                                          <v-flex md6>
                                            <v-text-field
                                              :id="`document-name-${indexFile}`"
                                              v-model="file.name"
                                              dense
                                              filled
                                              placeholder="File Name"
                                              solo
                                              flat
                                              color="cyan"
                                              class="pr-2 width-100"
                                            ></v-text-field>
                                          </v-flex>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col md="2" class="my-auto py-0">
                                    <v-row>
                                      <v-col md="6" class="py-0">
                                        <v-btn
                                          :disabled="wtx_attachments.length < 2"
                                          v-on:click="
                                            removeFileWarranty(indexFile)
                                          "
                                          class="white--text"
                                          depressed
                                          color="red darken-4"
                                          tile
                                          style="margin-left: -10px"
                                        >
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col md="6" class="py-0">
                                        <v-btn
                                          :disabled="wtx_attachments.length > 4"
                                          v-on:click="addMoreWarranty()"
                                          class="white--text"
                                          depressed
                                          color="cyan"
                                          tile
                                          style="margin-left: -13px"
                                        >
                                          <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                            <!--  <v-row>
                                <v-col lg="12">
                                  <label
                                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                    >Remarks</label
                                  >
                                  <v-textarea
                                    v-model.trim="asset.warranty_remarks"
                                    auto-grow
                                    dense
                                    filled
                                    color="cyan"
                                    placeholder="Asset Remark"
                                    solo
                                    flat
                                    row-height="15"
                                    v-on:paste="(e) => onPaste(e)"
                                    v-on:keypress="(e) => manageLimit(e)"
                                  ></v-textarea>
                                  <v-col md="12" class="text-right"
                                    >{{
                                      asset.description
                                        ? asset.description.length
                                        : 0
                                    }}/200</v-col
                                  >
                                </v-col>
                              </v-row> -->
                          </v-card-text>
                        </v-card>
                      </v-container>
                    </perfect-scrollbar>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <v-tab-item :value="'tab-attachment'">
                <v-layout>
                  <v-col cols="12" :md="12" :sm="12">
                    <v-card
                      v-if="false"
                      flat
                      class="custom-grey-border remove-border-radius"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          <!-- Documents -->Images & Attachments
                        </h3>
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <FileUpload v-model="wtz_attachments"></FileUpload>
                      </v-card-text>
                    </v-card>
                    <DocumnetList
                      v-model="wtz_attachments"
                      :document-type="docType"
                      tag-type="client_equipment"
                    ></DocumnetList>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <v-tab-item
                :value="'tab-preventive-maintanance'"
                v-if="assetTab == 'tab-preventive-maintanance'"
              >
                <v-layout
                  class="iv-custom-field d-block customer-create-new-customer"
                >
                  <v-col
                    class="custom-content-container pt-0 px-0"
                    cols="12"
                    :md="9"
                    :sm="9"
                  >
                    <perfect-scrollbar
                      :options="{ suppressScrollX: true }"
                      class="scroll"
                      style="
                        max-height: calc(100vh - 150px);
                        position: relative;
                      "
                    >
                      <v-container fluid class="px-0">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <h3
                              class="font-weight-700 custom-headline color-custom-blue"
                            >
                              Maintenance Information
                            </h3>
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Duration</label
                                >
                                <v-text-field
                                  filled
                                  placeholder="Duration"
                                  solo
                                  flat
                                  color="cyan"
                                  dense
                                  v-model.trim="asset.preventive_duration"
                                  v-mask="'###'"
                                  v-on:keyup="
                                    (e) =>
                                      updatePreventiveDuration(
                                        e,
                                        'warranty_duration'
                                      )
                                  "
                                  :disabled="equipmentId > 0 ? true : false"
                                ></v-text-field>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Duration Type</label
                                >
                                <v-select
                                  :items="warrantyDurationTypes"
                                  dense
                                  filled
                                  v-model.trim="asset.preventive_duration_type"
                                  solo
                                  flat
                                  class="pl-2 width-100"
                                  placeholder="Duration Type"
                                  color="cyan"
                                  item-text="text"
                                  item-value="value"
                                  item-color="cyan"
                                  v-on:change="
                                    (e) =>
                                      updatePreventiveDuration(
                                        e,
                                        'warranty_duration_type'
                                      )
                                  "
                                  :disabled="equipmentId > 0 ? true : false"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="6">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 required"
                                  >Start Date
                                </label>
                                <DatePicker
                                  solo
                                  flat
                                  :nullable="true"
                                  placeholder="Start Date"
                                  v-model="asset.preventive_start_date"
                                  :default-date="asset.preventive_start_date"
                                  :disabled="dateDisable()"
                                  :rules="[
                                    validateRules.required(
                                      asset.preventive_start_date,
                                      'preventive_start_date'
                                    ),
                                  ]"
                                  v-on:change="
                                    (e) =>
                                      updatePreventiveDuration(
                                        e,
                                        'preventive_start_date'
                                      )
                                  "
                                  :offWeekend="false"
                                ></DatePicker>
                              </v-col>
                              <v-col lg="6">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100 required"
                                  >End Date</label
                                >
                                <div class="pl-2">
                                  <DatePicker
                                    solo
                                    flat
                                    :nullable="true"
                                    placeholder="End Date"
                                    v-model="asset.preventive_end_date"
                                    :default-date="asset.preventive_end_date"
                                    :min-date="asset.preventive_start_date"
                                    :disabled="dateDisable()"
                                    :rules="[
                                      validateRules.required(
                                        asset.preventive_end_date,
                                        'preventive_end_date'
                                      ),
                                    ]"
                                    v-on:change="
                                      (e) =>
                                        updatePreventiveDuration(
                                          e,
                                          'preventive_end_date'
                                        )
                                    "
                                    :offWeekend="false"
                                  ></DatePicker>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="12">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Maintenance Cost</label
                                >
                                <v-text-field
                                  type="number"
                                  id="cost1"
                                  v-model.trim.number="asset.preventive_cost"
                                  dense
                                  filled
                                  placeholder="Cost"
                                  solo
                                  flat
                                  color="cyan"
                                  class="width-100"
                                  :disabled="equipmentId > 0 ? true : false"
                                  :min="0"
                                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="12">
                                <label
                                  class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                  >Description</label
                                >
                                <TextAreaField
                                  auto-grow
                                  dense
                                  filled
                                  color="cyan"
                                  v-model.trim="asset.preventive_description"
                                  placeholder="Description"
                                  solo
                                  flat
                                  row-height="25"
                                  counter="200"
                                />
                              </v-col>
                            </v-row>
                            <!-- maintenance attachments -->
                            <div class="mt-5">
                              <FileUpload v-model="mtx_attachments">
                              </FileUpload>
                            </div>
                            <!-- <FileUpload v-model="mtx_attachments"></FileUpload> -->
                            <v-row>
                              <v-col lg="12">
                                <template
                                  v-if="
                                    false &&
                                    mtx_attachments &&
                                    mtx_attachments.length > 0
                                  "
                                >
                                  <template
                                    v-for="(file, indexFile) in mtx_attachments"
                                  >
                                    <v-row
                                      :key="`maintanance-file-row-${indexFile}`"
                                      :class="{
                                        'table-alternate-row': indexFile % 2,
                                      }"
                                    >
                                      <v-col md="12" class="py-0">
                                        <label
                                          class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                          >Attachment</label
                                        >
                                      </v-col>
                                      <v-col md="10" class="py-0">
                                        <v-row>
                                          <v-col md="12" class="py-0">
                                            <v-layout>
                                              <v-flex md6>
                                                <v-file-input
                                                  :id="`maintanance-document-file-${indexFile}`"
                                                  placeholder="Select File"
                                                  class="removeShadow"
                                                  prepend-icon=""
                                                  dense
                                                  solo
                                                  prepend-inner-icon="mdi-attachment"
                                                  hide-details
                                                  v-model="file.file"
                                                  v-on:change="
                                                    updateFileMaintanance(
                                                      indexFile,
                                                      $event
                                                    )
                                                  "
                                                  v-on:click:clear="
                                                    updateFileMaintanance(
                                                      indexFile,
                                                      $event
                                                    )
                                                  "
                                                ></v-file-input>
                                              </v-flex>
                                              <v-flex md6>
                                                <v-text-field
                                                  :id="`document-name-${indexFile}`"
                                                  v-model="file.name"
                                                  dense
                                                  filled
                                                  placeholder="File Name"
                                                  solo
                                                  flat
                                                  color="cyan"
                                                  class="pr-2 width-100"
                                                ></v-text-field>
                                              </v-flex>
                                            </v-layout>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <v-col md="2" class="my-auto py-0">
                                        <v-row>
                                          <v-col md="6" class="py-0">
                                            <v-btn
                                              :disabled="
                                                mtx_attachments.length < 2
                                              "
                                              v-on:click="
                                                removeFileMaintanance(indexFile)
                                              "
                                              class="white--text"
                                              depressed
                                              color="red darken-4"
                                              tile
                                              style="margin-left: -10px"
                                            >
                                              <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                          </v-col>
                                          <v-col md="6" class="py-0">
                                            <v-btn
                                              :disabled="
                                                mtx_attachments.length > 4 ||
                                                equipmentId > 0
                                              "
                                              v-on:click="addMoreMaintanance()"
                                              class="white--text"
                                              depressed
                                              color="cyan"
                                              tile
                                              style="margin-left: -13px"
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </template>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-container>
                    </perfect-scrollbar>
                  </v-col>
                </v-layout>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col cols="12" :md="2" :sm="2" class="text-center"></v-col>
        </v-row>
      </v-form>
      <ManageBrand
        :b-dialog="manage_brand"
        :brand="brands"
        Equipment
        v-on:close-dialog="manage_brand = false"
        v-on:get-product-brand="get_options"
      ></ManageBrand>
      <ManageEquipmentLocation
        :dialog="manage_location"
        :equipment="equipment_locations"
        v-on:close-dialog="manage_location = false"
        v-on:get-equipment-location="get_options"
      ></ManageEquipmentLocation>
      <template v-if="manageGroupDialog">
        <ManageCategory
          Equipment
          :c-dialog="manageGroupDialog"
          :category="categoryList"
          v-on:close-dialog="manageGroupDialog = false"
          v-on:get-product-category="get_options"
        ></ManageCategory>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import RadioInputCustom from "@/view/components/RadioInputCustom.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  POST,
  CLEAR_ERROR,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { cloneDeep, findIndex, toSafeInteger /*  filter  */ } from "lodash";
import {
  /* SET_MESSAGE, */ SET_ERROR,
} from "@/core/services/store/common.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import DatePicker from "@/view/components/DatePicker.vue";
import MomentJS from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageEquipmentLocation from "@/view/pages/partials/Manage-Equipment-Location.vue";
// import FileUpload from "@/view/components/FileUpload";
//import FileUpload from "@/view/components/app-component/FileInput.vue";
import DocumnetList from "@/view/pages/DocumnetList";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

export default {
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, FileManagerMixin],
  name: "customer-create",
  title: "Create Asset",
  //emits: ['phone-number'],
  data() {
    return {
      docType: 37,
      filteredProduct: {
        category_name: null,
        model: null,
        name: null,
        description: null,
        serial_number: null,
        product_image: null,
        brand: null,
        model_no: null,
      },
      unique_barcode: null,
      leadId: 0,
      imageLoading: false,
      customerList: [],
      formValid: true,
      formLoading: false,
      manage_brand: false,
      manage_location: false,
      showlocation: null,
      files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      equipment_files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      durationTypeList: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      lead: {},
      minDate: null,
      categoryList: [],
      equipmentId: 0,
      manageGroupDialog: false,
      propertyAddress: {},
      billingAddress: {},
      all_customers: [],
      all_property: [],
      brands: [],
      products: [],
      supplierList: [],
      equipment_locations: [],
      filtered_property: [],
      LocationList: [],
      contactDetail: {},
      contactPerson: [],
      notification: {},
      updatedImg: null,
      asset: {
        do_no: null,
        arrival_date: null,
        specification: null,
        common_cert_no: null,
        sales_employee: null,
        delivery_date: null,
        delivery_remark: null,
        salesperson: null,
        supplier: null,
        purchase_price: null,
        purchase_date: null,
        quotation_no: null,
        invoice_no: null,
        selling_price: 0,
        batch_number: null,
        check_maintanance: false,
        preventive_duration_type: "month",
        preventive_start_date: MomentJS().format("YYYY-MM-DD"),
        preventive_end_date: null,
        preventive_duration: 0,
        preventive_description: null,
        asset_type: "personal",
        name: null,
        main_type: "main_customer",
        customer: 0,
        brand: null,
        installation_date: null,
        activation_date: null,
        end_life: null,
        site_location: null,
        property: 0,
        product: 0,
        asset_image: null,
        category: null,
        pico_barcode: null,
        serial_no: null,
        location: 0,
        model: 0,
        check_warranty: false,
        is_return: false,
        is_purchase_info: false,
        description: null,
        tags: [],
        warranty_start_date: null,
        warranty_end_date: null,
        warranty_duration: 0,
        warranty_duration_type: "month",
        warranty_remark: null,
        warranty_reference: null,
        warranty_cost: 0,
        warranty_reminder: [
          {
            type: "after",
            duration: null,
            duration_type: "Day",
            reminder_type: null,
          },
        ],
        company_number: null,
      },
      warrantyDurationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      wtz_attachments: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      wtx_attachments: Array({
        file: null,
        url: null,
        name: null,
      }),
      mtx_attachments: Array({
        file: null,
        url: null,
        name: null,
      }),
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          permission: "customer",
          status: true,
        },
        {
          title: "Allocate Details",
          icon: "mdi-account-multiple",
          key: "allocate-details",
          permission: "contact-person",
          status: false,
        },
        {
          title: "Warranty Details",
          icon: "mdi-home-map-marker",
          key: "warranty",
          permission: "property",
          status: true,
        },
        {
          title: "Attachment",
          icon: "mdi-file",
          key: "attachment",
          permission: "property",
          status: true,
        },
        {
          title: "Preventive Maintenance",
          icon: "mdi-credit-card",
          key: "preventive-maintanance",
          permission: "property",
          status: true,
        },
        /*   {
          title: "Maintenance Details",
          icon: "mdi-credit-card",
          key: "maintenance",
          permission: "customer",
          status: true,
        }, */
      ],
      assetTab: "tab-overview",
      isCustomerCreated: false,
      isAddressCreated: false,
      options: {},
      steps: {
        details: false,
        "contact-person": true,
        property: true,
        maintenance: true,
        notification: true,
      },
    };
  },
  props: {
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PhoneTextField,
    TagAutoComplete,
    CreateUpdateTemplate,
    ManageCategory,
    DatePicker,
    ManageBrand,
    Barcode,
    RadioInputCustom,
    ManageEquipmentLocation,
    //FileUpload,
    DocumnetList,
    TextAreaField,
  },
  watch: {
    wtx_attachments(param) {
      console.log("wtx_attachments", param);
    },
    mtx_attachments(param) {
      console.log("mtx_attachments", param);
    },
    assetTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.assetForm.validate();
      }, 200);
    },

    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },

    /* customerId:{
      deep:true,
      immediate : true,
      handler(param) {
        if(param){
          this.filter_property(param)
        }
      }
    } */
  },
  methods: {
    getDates() {
      if (!this.asset?.installation_date || !this.asset?.end_life) {
        return false;
      }

      let date1 = new Date(this.asset?.installation_date);
      let date2 = new Date(this.asset?.end_life);

      if (isNaN(date1) || isNaN(date2)) {
        return "Invalid date format.";
      }

      let years = date2.getFullYear() - date1.getFullYear();
      let months = date2.getMonth() - date1.getMonth();
      let days = date2.getDate() - date1.getDate();

      if (days < 0) {
        months--;
        days += new Date(date2.getFullYear(), date2.getMonth(), 0).getDate();
      }

      if (months < 0) {
        years--;
        months += 12;
      }
      let result = [];
      if (years > 0) result.push(`${years} year${years > 1 ? "s" : ""}`);
      if (months > 0) result.push(`${months} month${months > 1 ? "s" : ""}`);
      if (days > 0) result.push(`${days} day${days > 1 ? "s" : ""}`);

      return result.length > 0 ? `${result.join(", ")}` : "-";
    },
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.barcode.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    getLocation(id) {
      let checkData = this.lodash.find(this.filtered_property, { id: id });
      this.showlocation = checkData;
    },
    dateDisable() {
      if (this.equipmentId > 0) {
        return true;
      } else {
        return false;
      }
    },
    /*
    updateFileEquipment(indexFile, file) {
      if (file && file.name) {
        this.wtz_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.wtz_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()}`;
      } else {
        this.wtz_attachments[indexFile].name = null;
        this.wtz_attachments[indexFile].suffix = null;
      }
    },
     updateFile(indexFile, file) {
      if (file && file.name) {
        this.wtx_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.wtx_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()}`;
      } else {
        this.wtx_attachments[indexFile].name = null;
        this.wtx_attachments[indexFile].suffix = null;
      }
    },

    updateFileMaintanance(indexFile, file) {
      if (file && file.name) {
        this.mtx_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.mtx_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()}`;
      } else {
        this.mtx_attachments[indexFile].name = null;
        this.mtx_attachments[indexFile].suffix = null;
      }
    }, */

    checkType() {
      if (this.asset.main_type == "main_company") {
        this.asset.asset_type = "company";
      } else {
        this.asset.asset_type = "personal";
      }
    },
    blockSpecialCharacters(event) {
      // Get the key code of the pressed key
      const keyCode = event.keyCode;

      // Allow alphanumeric characters (48-57: 0-9, 65-90: A-Z, 97-122: a-z)
      const isAlphanumeric =
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122);

      // Allow space (32) and backspace (8)
      const isSpaceOrBackspace = keyCode === 32 || keyCode === 8;

      // If the pressed key is not alphanumeric, space, or backspace, prevent default behavior (typing)
      if (!isAlphanumeric && !isSpaceOrBackspace) {
        event.preventDefault();
      }
    },
    handlePaste(event) {
      // Prevent the default paste behavior
      event.preventDefault();

      // Get the pasted text
      const pastedText = event.clipboardData.getData("text/plain");

      // Define a regular expression to match allowed characters (letters and numbers in this example)
      const allowedCharsRegex = /[A-Za-z0-9]/g;

      // Filter out unwanted characters
      const filteredText = pastedText.match(allowedCharsRegex).join("");

      // Update the input value with the filtered text
      this.asset.serial_no = filteredText;
    },
    productData(product) {
      if (product > 0) {
        let filtered_product = this.lodash.filter(this.products, {
          id: product,
        });
        // if(filtered_product){
        //   this.filteredProduct.category_name = filtered_product[0].category;
        //   this.filteredProduct.model = filtered_product[0].model;
        //   this.filteredProduct.name = filtered_product[0].name;
        //   this.filteredProduct.description = filtered_product[0].description;
        //   this.filteredProduct.serial_number = filtered_product[0].serial_number;
        //   this.filteredProduct.product_image = filtered_product[0].product_image;
        // }
        this.setProductData(product);
        //console.log(this.filteredProduct);
        this.asset.name = filtered_product[0].name;
        this.asset.brand = filtered_product[0].brand;
        this.asset.serial_no = null; //filtered_product[0].barcode
        this.asset.category = filtered_product[0].category;
        this.asset.warranty_duration_type = filtered_product[0].duration_time;
        this.asset.warranty_duration = filtered_product[0].warranty_check;
      }
    },
    setProductData(product = 0) {
      if (product > 0) {
        let filtered_product = this.lodash.filter(this.products, {
          id: product,
        });
        if (filtered_product) {
          this.filteredProduct.category_name = filtered_product[0].category;
          this.filteredProduct.model = filtered_product[0].model;
          this.filteredProduct.name = filtered_product[0].name;
          this.filteredProduct.description = filtered_product[0].description;
          this.filteredProduct.serial_number = filtered_product[0].barcode; //changed from serial_number
          this.filteredProduct.product_image =
            filtered_product[0].product_image;
          this.filteredProduct.brand = filtered_product[0].brand;
          this.filteredProduct.model = filtered_product[0].model;
          this.filteredProduct.model_no = filtered_product[0].model_no;
        }
      }
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    /*   new_equipment() {
      this.new_equipment_dailog = true;
      this.filter_property();
    }, */

    get_Equipment() {
      this.formLoading = true;
      this.$store
        .dispatch(GET, { url: `equipment/${this.equipmentId}` })
        .then(({ data }) => {
          this.unique_barcode = data.barcode;

          (this.updatedImg = data.image ? data.image : null),
            (this.asset.asset_type = data.type ? data.type : null);
          this.asset.name = data.name ? data.name : null;
          this.asset.main_type = data.main_type ? data.main_type : null;
          this.asset.end_life = data.end_life ? data.end_life : null;
          this.asset.company_number = data.company_number
            ? data.company_number
            : null;
          this.asset.customer =
            data.customer && data.customer.id ? data.customer.id : 0;
          this.asset.property =
            data.property && data.property.id ? data.property.id : 0;
          this.filter_property(this.asset.customer);
          this.getLocation(this.asset.property);
          this.asset.brand = data.brand ? data.brand : null;
          this.asset.installation_date = data.installation_date
            ? data.installation_date
            : null;
          this.asset.activation_date = data.activation_date
            ? data.activation_date
            : null;
          this.asset.product = data.product ? data.product : 0;
          this.asset.category = data.category ? data.category : null;
          this.asset.specification = data.specification
            ? data.specification
            : null;
          this.asset.salesperson = data.salesperson ? data.salesperson : null;
          this.asset.purchase_price = data.purchase_price
            ? data.purchase_price
            : null;
          this.asset.purchase_date = data.purchase_date
            ? data.purchase_date
            : null;
          this.asset.invoice_no = data.invoice_no ? data.invoice_no : null;
          this.asset.quotation_no = data.quotation_no
            ? data.quotation_no
            : null;
          this.asset.arrival_date = data.arrival_date
            ? data.arrival_date
            : null;

          if (this.asset.product) {
            this.setProductData(this.asset.product);
          }
          // if(filtered_product){
          // this.filteredProduct.category_name = data.category ? data.category : null;
          // this.filteredProduct.model = data.model ? data.model : null;
          // this.filteredProduct.name = data.category ? data.category : null;
          // this.filteredProduct.description = filtered_product[0].description;
          // this.filteredProduct.serial_number = filtered_product[0].serial_number;
          // this.filteredProduct.product_image = filtered_product[0].product_image;
          // }

          /*    this.asset.asset_image = data.asset_image ? data.asset_image : null; */
          this.asset.model = data.model ? data.model : null;
          this.asset.location =
            data.location && data.location.id
              ? this.lodash.toString(data.location.id)
              : null;

          this.asset.serial_no = data.serial_no ? data.serial_no : null;

          this.asset.check_warranty = data.has_warranty;
          this.changeWarrantyCheck(this.asset.check_warranty);

          this.asset.check_maintanance =
            data.has_preventive_maintanance == "0" ||
            data.has_preventive_maintanance == 0
              ? 0
              : 1;
          this.changeMaintananceCheck(this.asset.check_maintanance);

          this.asset.is_purchase_info = data.is_purchase_info
            ? data.is_purchase_info
            : 0;
          this.asset.purchased_on = data.purchased_on
            ? data.purchased_on
            : null;
          this.asset.cost_price = data.cost_price ? data.cost_price : 0;
          this.asset.vendor =
            data.supplier && data.supplier.id ? data.supplier.id : 0;

          this.asset.description = data.description ? data.description : null;
          this.asset.tags =
            data.client_tags /*  && data.tags.length ? data.tags : [] */;
          this.asset.warranty_start_date =
            data.warranty && data.warranty.start_date
              ? data.warranty.start_date
              : null;
          this.asset.warranty_end_date =
            data.warranty && data.warranty.end_date
              ? data.warranty.end_date
              : null;
          this.asset.warranty_duration_type =
            data.warranty && data.warranty.duration_type
              ? data.warranty.duration_type
              : null;
          this.asset.warranty_duration =
            data.warranty && data.warranty.duration
              ? data.warranty.duration
              : 0;

          this.asset.warranty_cost =
            data.warranty && data.warranty.cost ? data.warranty.cost : 0;
          this.asset.warranty_reference =
            data.warranty && data.warranty.reference
              ? data.warranty.reference
              : null;
          this.asset.warranty_remark =
            data.warranty && data.warranty.remark ? data.warranty.remark : null;

          //preventive set details
          this.asset.preventive_start_date =
            data.preventive_maintanance &&
            data.preventive_maintanance.start_date
              ? data.preventive_maintanance.start_date
              : null;
          this.asset.preventive_end_date =
            data.preventive_maintanance && data.preventive_maintanance.end_date
              ? data.preventive_maintanance.end_date
              : null;
          this.asset.preventive_duration_type =
            data.preventive_maintanance &&
            data.preventive_maintanance.duration_type
              ? data.preventive_maintanance.duration_type
              : null;
          this.asset.preventive_duration =
            data.preventive_maintanance && data.preventive_maintanance.duration
              ? data.preventive_maintanance.duration
              : 0;
          this.asset.preventive_cost =
            data.preventive_maintanance && data.preventive_maintanance.cost
              ? data.preventive_maintanance.cost
              : 0;
          this.asset.preventive_description =
            data.preventive_maintanance && data.preventive_maintanance.remark
              ? data.preventive_maintanance.remark
              : null;
          this.asset.batch_number =
            data && data.batch_number ? data.batch_number : null;
          this.asset.selling_price =
            data && data.selling_price ? data.selling_price : null;

          this.asset.asset_image = data.image;

          this.asset.do_no = data.do_no ? data.do_no : null;
          this.asset.common_cert_no = data.common_cert_no
            ? data.common_cert_no
            : null;
          this.asset.sales_employee = data.sales_employee
            ? data.sales_employee
            : null;
          this.asset.delivery_date = data.delivery_date
            ? data.delivery_date
            : null;
          this.asset.delivery_remark = data.delivery_remark
            ? data.delivery_remark
            : null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    get_options() {
      this.formLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "equipment/options",
          data: {
            type: "main_customer",
          },
        })
        .then(({ data }) => {
          this.customerList = data.customers;
          this.supplierList = data.suppliers;
          this.all_property = data.properties;
          if (!this.equipmentId && this.customerId && this.isCustomer) {
            this.filter_property(this.customerId);
          }
          this.brands = data.brands;
          this.categoryList = data.category;
          this.products = data.products;
          this.equipment_locations = data.equipment_locations;
          if (!this.equipmentId) {
            this.unique_barcode = data && data.barcode;
          }
          //added here for site loc empty issue on update
          if (this.equipmentId > 0) {
            this.get_Equipment();
          }
          /* this.filtered_property = this.lodash.filter(this.all_property, {
            customer: this.asset.customer,
          }); */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    filter_property(param) {
      this.filtered_property = this.lodash.filter(this.all_property, {
        customer: param,
      });
    },
    manageReminder(index, type) {
      let reminderData = cloneDeep(this.asset.maintenance_reminder);
      let endDate = this.asset.maintenance_end_date;
      if (type == "warranty") {
        reminderData = cloneDeep(this.asset.warranty_reminder);
        endDate = this.asset.warranty_end_date;
      }
      if (
        reminderData[index] &&
        reminderData[index].duration &&
        reminderData[index].duration_type &&
        endDate
      ) {
        endDate = MomentJS(endDate, "YYYY-MM-DD");
        let reminderDate = null;
        if (reminderData[index].type == 0) {
          reminderDate = endDate.subtract(
            reminderData[index].duration,
            reminderData[index].duration_type
          );
        } else {
          reminderDate = endDate.add(
            reminderData[index].duration,
            reminderData[index].duration_type
          );
        }
        if (type == "warranty") {
          this.asset.warranty_end_date[index].date =
            reminderDate.format("YYYY-MM-DD");
        }
      }
    },
    /* addMoreWarranty() {
      this.wtx_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },
    removeFileWarranty(index) {
      this.wtx_attachments.splice(index, 1);
      if (this.wtx_attachments.length < 1) {
        this.addMoreWarranty();
      }
    },

    addMoreEquipment() {
      this.wtz_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },
    removeFileEquipment(index) {
      this.wtz_attachments.splice(index, 1);
      if (this.wtz_attachments.length < 1) {
        this.addMoreEquipment();
      }
    }, */
    // addMoreMaintanance() {
    //   this.mtx_attachments.push({
    //     file: null,
    //     url: null,
    //     name: null,
    //   });
    // },
    /* removeFileMaintanance(index) {
      this.mtx_attachments.splice(index, 1);
      if (this.mtx_attachments.length < 1) {
        this.addMoreMaintanace();
      }
    },
    addMoreMaintanance() {
      this.mtx_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    }, */

    // removeFileMaintenance(index) {
    //   this.mtx_attachments.splice(index, 1);
    //   if (this.mtx_attachments.length < 1) {
    //     this.addMoreWarranty();
    //   }
    // },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          let data = _this.lodash.head(response);
          _this.asset.asset_image =
            data && data.file && data.file.url ? data.file.url : null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    addMoreWarrantyReminder() {
      this.asset.warranty_reminder.push({
        type: 0,
        duration: null,
        duration_type: "day",
        reminder_type: null,
      });
    },
    addMoreMaintenanceReminder() {
      this.asset.maintenance_reminder.push({
        type: 0,
        duration: null,
        duration_type: "day",
        reminder_type: null,
      });
    },
    removeWarrantyReminder(index) {
      this.asset.warranty_reminder.splice(index, 1);
      if (this.asset.warranty_reminder.length < 1) {
        this.addMoreWarrantyReminder();
      }
    },
    removeMaintenanceReminder(index) {
      this.asset.maintenance_reminder.splice(index, 1);
      if (this.asset.maintenance_reminder.length < 1) {
        this.addMoreWarrantyReminder();
      }
    },
    updateWarrantyDuration(e, type) {
      let warranty_start_date = e;
      if (type != "start_date") {
        warranty_start_date =
          this.asset && this.asset.warranty_start_date
            ? this.asset.warranty_start_date
            : null;
      }
      if (warranty_start_date) {
        let { warranty_duration_type, warranty_duration } = this.asset;
        let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
        let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
        switch (warranty_duration_type) {
          case "day":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "d"
            );
            break;
          case "month":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "M"
            );
            break;
          case "year":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "y"
            );
            break;
        }
        this.asset.warranty_end_date = date.format("YYYY-MM-DD");
      }
    },

    updatePreventiveDuration(e, type) {
      let preventive_start_date = e;
      if (type != "start_date") {
        preventive_start_date =
          this.asset && this.asset.preventive_start_date
            ? this.asset.preventive_start_date
            : null;
      }
      if (preventive_start_date) {
        let { preventive_duration_type, preventive_duration } = this.asset;
        let date = MomentJS(preventive_start_date, "YYYY-MM-DD");
        let duration = preventive_duration
          ? toSafeInteger(preventive_duration)
          : 0;
        switch (preventive_duration_type) {
          case "day":
            date = MomentJS(preventive_start_date, "YYYY-MM-DD").add(
              duration,
              "d"
            );
            break;
          case "month":
            date = MomentJS(preventive_start_date, "YYYY-MM-DD").add(
              duration,
              "M"
            );
            break;
          case "year":
            date = MomentJS(preventive_start_date, "YYYY-MM-DD").add(
              duration,
              "y"
            );
            break;
        }
        this.asset.preventive_end_date = date.format("YYYY-MM-DD");
      }
    },

    changeWarrantyCheck(data) {
      let warrantyIndex = findIndex(this.tabs, (row) => row.key == "warranty");
      this.tabs[warrantyIndex].status = data;
    },
    changeMaintananceCheck(data) {
      // true or false

      let maintenanceIndex = findIndex(
        this.tabs,
        (row) => row.key == "preventive-maintanance"
      );
      this.tabs[maintenanceIndex].status =
        data == "0" || data == 0 ? false : true;
    },

    async updateOrCreate() {
      const _this = this;
      const formErrors = _this.validateForm(_this.$refs.assetForm);

      if (formErrors.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
        return false;
      }

      if (!_this.$refs.assetForm.validate()) {
        return false;
      }

      if (this.asset.check_warranty && !this.asset.warranty_start_date) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Warranty start date is required")
        );
        return false;
      }
      if (this.asset.check_warranty && !this.asset.warranty_end_date) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Warranty end date is required")
        );
        return false;
      }
      /* let attachment = filter(_this.mtx_attachments, function(row){
        console.log(row);
        return row;
      }); */

      let formData = new FormData();
      formData.append("type", this.asset.asset_type);
      if (this.asset.main_type == "main_company") {
        formData.append("customer", 0);
        formData.append("property", 0);
      }
      if (this.asset.main_type == "main_customer") {
        formData.append(
          "customer",
          this.asset.customer ? this.asset.customer : this.customerId
        );
        formData.append(
          "property",
          this.asset.property ? this.asset.property : 0
        );
      }
      formData.append("name", this.asset.name ? this.asset.name : 0);
      formData.append(
        "serial_no",
        this.asset.serial_no ? this.asset.serial_no : null
      );
      if (this.asset && this.asset.category) {
        formData.append(
          "category",
          this.asset.category ? this.asset.category : null
        );
      }
      if (this.asset && this.asset.installation_date) {
        formData.append(
          "installation_date",
          this.asset.installation_date ? this.asset.installation_date : null
        );
      }
      if (this.asset && this.asset.activation_date) {
        formData.append(
          "activation_date",
          this.asset.activation_date ? this.asset.activation_date : null
        );
      }
      /*  if (this.asset && this.asset.main_type && !this.isCustomer) { */
      formData.append(
        "main_type",
        this.asset.main_type ? this.asset.main_type : null
      );
      /*   } */
      if (this.asset && this.asset.end_life) {
        formData.append(
          "end_life",
          this.asset.end_life ? this.asset.end_life : null
        );
      }
      if (this.asset && this.asset.company_number) {
        formData.append(
          "company_number",
          this.asset.company_number ? this.asset.company_number : null
        );
      }
      if (this.asset && this.asset.cost) {
        formData.append("cost", this.asset.cost ? this.asset.cost : 0);
      }
      if (this.asset && this.asset.location) {
        formData.append(
          "location",
          this.asset.location ? this.asset.location : 0
        );
      }
      if (this.asset && this.asset.product) {
        formData.append("product", this.asset.product ? this.asset.product : 0);
      }
      if (this.asset && this.asset.model) {
        formData.append("model", this.asset.model ? this.asset.model : null);
      }
      if (this.asset && this.asset.asset_image) {
        formData.append("asset_image", this.asset.asset_image);
      }

      if (this.asset && this.asset.brand) {
        formData.append("brand", this.asset.brand ? this.asset.brand : null);
      }
      if (this.asset && this.asset.description) {
        formData.append(
          "description",
          this.asset.description ? this.asset.description : null
        );
      }
      if (this.asset && this.asset.check_warranty) {
        formData.append("check_warranty", this.asset.check_warranty ? 1 : 0);
      }
      if (this.asset && this.asset.is_purchase_info) {
        formData.append(
          "is_purchase_info",
          this.asset.is_purchase_info ? 1 : 0
        );
      }
      if (this.asset && this.asset.is_purchase_info && this.asset.cost_price) {
        formData.append(
          "cost_price",
          this.asset.cost_price ? this.asset.cost_price : 0
        );
      }
      if (
        this.asset &&
        this.asset.is_purchase_info &&
        this.asset.purchased_on
      ) {
        formData.append(
          "purchased_on",
          this.asset.purchased_on ? this.asset.purchased_on : null
        );
      }

      //delivery info
      if (this.asset && this.asset.do_no && this.asset.do_no) {
        formData.append("do_no", this.asset.do_no ? this.asset.do_no : null);
      }
      if (
        this.asset &&
        this.asset.common_cert_no &&
        this.asset.common_cert_no
      ) {
        formData.append(
          "common_cert_no",
          this.asset.common_cert_no ? this.asset.common_cert_no : null
        );
      }
      if (
        this.asset &&
        this.asset.sales_employee &&
        this.asset.sales_employee
      ) {
        formData.append(
          "sales_employee",
          this.asset.sales_employee ? this.asset.sales_employee : null
        );
      }
      if (this.asset && this.asset.delivery_date && this.asset.delivery_date) {
        formData.append(
          "delivery_date",
          this.asset.delivery_date ? this.asset.delivery_date : null
        );
      }
      if (
        this.asset &&
        this.asset.delivery_remark &&
        this.asset.delivery_remark
      ) {
        formData.append(
          "delivery_remark",
          this.asset.delivery_remark ? this.asset.delivery_remark : null
        );
      }
      if (
        this.asset &&
        this.asset.warranty_remark &&
        this.asset.warranty_remark
      ) {
        formData.append(
          "warranty_remark",
          this.asset.warranty_remark ? this.asset.warranty_remark : null
        );
      }
      if (
        this.asset &&
        this.asset.warranty_reference &&
        this.asset.warranty_reference
      ) {
        formData.append(
          "warranty_reference",
          this.asset.warranty_reference ? this.asset.warranty_reference : null
        );
      }

      if (this.asset && this.asset.vendor) {
        formData.append("vendor", this.asset.vendor ? this.asset.vendor : 0);
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_duration
      ) {
        formData.append(
          "warranty_duration",
          this.asset.warranty_duration ? this.asset.warranty_duration : 0
        );
      }
      if (this.asset && this.asset.specification) {
        formData.append(
          "specification",
          this.asset.specification ? this.asset.specification : null
        );
      }

      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_duration_type
      ) {
        formData.append(
          "warranty_duration_type",
          this.asset.warranty_duration_type
            ? this.asset.warranty_duration_type
            : null
        );
      }

      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_start_date
      ) {
        formData.append(
          "warranty_start_date",
          this.asset.warranty_start_date ? this.asset.warranty_start_date : null
        );
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_end_date
      ) {
        formData.append(
          "warranty_end_date",
          this.asset.warranty_end_date ? this.asset.warranty_end_date : null
        );
      }
      if (this.asset && this.asset.check_warranty && this.asset.warranty_cost) {
        formData.append(
          "warranty_cost",
          this.asset.warranty_cost ? this.asset.warranty_cost : 0
        );
      }

      if (
        this.asset &&
        this.asset.tags &&
        this.asset.tags.length &&
        this.asset.tags.length > 0
      ) {
        for (let i = 0; i < this.asset.tags.length; i++) {
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].type
          ) {
            formData.append(`tags[${i}][type]`, this.asset.tags[i].type);
          }
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].color
          ) {
            formData.append(`tags[${i}][color]`, this.asset.tags[i].color);
          }
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].text
          ) {
            formData.append(`tags[${i}][text]`, this.asset.tags[i].text);
          }
        }
      }
      if (this.asset && this.asset.salesperson) {
        formData.append(
          "salesperson",
          this.asset.salesperson ? this.asset.salesperson : null
        );
      }
      if (this.asset && this.asset.purchase_price) {
        formData.append(
          "purchase_price",
          this.asset.purchase_price ? this.asset.purchase_price : null
        );
      }
      if (this.asset && this.asset.purchase_date) {
        formData.append(
          "purchase_date",
          this.asset.purchase_date ? this.asset.purchase_date : null
        );
      }
      if (this.asset && this.asset.quotation_no) {
        formData.append(
          "quotation_no",
          this.asset.quotation_no ? this.asset.quotation_no : null
        );
      }
      if (this.asset && this.asset.invoice_no) {
        formData.append(
          "invoice_no",
          this.asset.invoice_no ? this.asset.invoice_no : null
        );
      }
      if (this.asset && this.asset.arrival_date) {
        formData.append(
          "arrival_date",
          this.asset.arrival_date ? this.asset.arrival_date : null
        );
      }
      if (
        this.asset &&
        this.asset.warranty_reminder &&
        this.asset.warranty_reminder.length &&
        this.asset.warranty_reminder.length > 0
      ) {
        for (let i = 0; i < this.asset.warranty_reminder.length; i++) {
          formData.append(
            `warranty_reminder[${i}][type]`,
            this.asset.warranty_reminder[i].type
              ? this.asset.warranty_reminder[i].type
              : 0
          );

          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].duration
          ) {
            formData.append(
              `warranty_reminder[${i}][duration]`,
              this.asset.warranty_reminder[i].duration
            );
          }
          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].duration_type
          ) {
            formData.append(
              `warranty_reminder[${i}][duration_type]`,
              this.asset.warranty_reminder[i].duration_type
            );
          }
          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].reminder_type
          ) {
            formData.append(
              `warranty_reminder[${i}][reminder_type]`,
              this.asset.warranty_reminder[i].reminder_type
            );
          }
        }
      }

      //preventive
      if (this.asset && this.asset.check_maintanance) {
        formData.append(
          "has_preventive_maintanance",
          this.asset.check_maintanance ? this.asset.check_maintanance : 0
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_cost
      ) {
        formData.append(
          "preventive_cost",
          this.asset.preventive_cost ? this.asset.preventive_cost : 0
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_description
      ) {
        formData.append(
          "preventive_description",
          this.asset.preventive_description
            ? this.asset.preventive_description
            : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_end_date
      ) {
        formData.append(
          "preventive_end_date",
          this.asset.preventive_end_date ? this.asset.preventive_end_date : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_start_date
      ) {
        formData.append(
          "preventive_start_date",
          this.asset.preventive_start_date
            ? this.asset.preventive_start_date
            : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_duration_type
      ) {
        formData.append(
          "preventive_duration_type",
          this.asset.preventive_duration_type
            ? this.asset.preventive_duration_type
            : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_duration
      ) {
        formData.append(
          "preventive_duration",
          this.asset.preventive_duration ? this.asset.preventive_duration : 0
        );
      }

      formData.append(
        "batch_number",
        this.asset.batch_number ? this.asset.batch_number : null
      );
      formData.append(
        "selling_price",
        this.asset.selling_price ? this.asset.selling_price : 0
      );

      if (
        this.wtx_attachments &&
        this.wtx_attachments.length &&
        this.wtx_attachments.length > 0
      ) {
        for (let i = 0; i < this.wtx_attachments.length; i++) {
          if (
            this.wtx_attachments &&
            this.wtx_attachments[i] &&
            this.wtx_attachments[i].file
          ) {
            formData.append(`file[${i}][file]`, this.wtx_attachments[i].file);
            formData.append(`file[${i}][name]`, this.wtx_attachments[i].name);
          }
        }
      }

      if (
        this.mtx_attachments &&
        this.mtx_attachments.length &&
        this.mtx_attachments.length > 0
      ) {
        for (let i = 0; i < this.mtx_attachments.length; i++) {
          if (
            this.mtx_attachments &&
            this.mtx_attachments[i] &&
            this.mtx_attachments[i].file
          ) {
            formData.append(
              `maintananace_file[${i}][file]`,
              this.mtx_attachments[i].file
            );
            formData.append(
              `maintananace_file[${i}][name]`,
              this.mtx_attachments[i].name
            );
          }
        }
      }

      //equipment files
      if (
        this.wtz_attachments &&
        this.wtz_attachments.length &&
        this.wtz_attachments.length > 0
      ) {
        for (let i = 0; i < this.wtz_attachments.length; i++) {
          if (
            this.wtz_attachments &&
            this.wtz_attachments[i] &&
            this.wtz_attachments[i].file
          ) {
            formData.append(
              `equipment_file[${i}][file]`,
              this.wtz_attachments[i].file
            );
            formData.append(
              `equipment_file[${i}][name]`,
              this.wtz_attachments[i].name
            );
          }
        }
      }

      let requestTYPE = POST;
      let requestURL = "";
      if (this.equipmentId > 0) {
        requestURL = `equipment-new/${this.equipmentId}`;
      } else {
        requestURL = "equipment-new";
      }
      this.formLoading = true;
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then(({ data }) => {
          this.forcePush = true;
          if (this.isCustomer) {
            _this.$emit("update:warranty", true);
            _this.$emit("close:warranty", true);
          }
          if (!this.isCustomer) {
            this.$router.push(
              this.getDefaultRoute("client-equipment.detail", {
                params: { id: data.id },
              })
            );
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
  mounted() {
    this.get_options();
    this.$refs.assetForm.validate();
    if (this.equipmentId > 0) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customer Asset", route: "client-equipment" },
        { title: "Update" },
      ]);
    } else {
      this.changeWarrantyCheck(false);
      this.changeMaintananceCheck(false);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customer Asset", route: "client-equipment" },
        { title: "Create" },
      ]);
    }
    if (this.asset?.installation_date && this.asset?.end_life) {
      this.getDates();
    }
  },
  created() {
    if (!this.isCreate) {
      this.equipmentId = this.lodash.toSafeInteger(this.$route.params.id);
    }
  },
  /*   beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  }, */
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  computed: {
    getProfileImage() {
      return this.asset.asset_image ? this.asset.asset_image : null;
      /*  return this.lodash.isEmpty(this.asset.asset_image) === false 
        ? this.asset.asset_image.file.url
        : null; */
    },
  },
};
</script>
<style scoped>
/* tabs css  */

.custom-tab-transparent.v-tabs--fixed-tabs > .v-tabs-bar .v-tab.v-tab--active {
  background-color: #00bcd4 !important;
  color: #fff !important;
}
.custom-tab-transparent.v-tabs--fixed-tabs
  > .v-tabs-bar
  .v-tab.v-tab--active
  .grey--text.text--darken-4 {
  color: #fff !important;
  caret-color: #fff !important;
}

.custom-tab-transparent.v-tabs .v-tab {
  background: #00bcd429;
  width: 25%;
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab.v-tab:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + -0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
</style>
